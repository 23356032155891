import React from 'react';

import Header from 'sections/ScreverOcxProgram/Header';
import Hero from 'sections/ScreverBtqProgram/Hero';
import PagesList from 'sections/ScreverOcxProgram/PagesList';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-btq-program/program',
  },
  {
    title: 'Cinema',
    url: '/events/screver-btq-program/cinema',
  },
  {
    title: 'Hackathons',
    url: '/events/screver-btq-program/vote',
  },
  {
    title: '✌🏻feedback',
    url: '/events/screver-btq-program/feedback',
  },
];

const seo = {
  title: 'Screver Boutique Event: Let’s talk & Co-work | Agenda',
  metaDesc:
    "We're rolling out the red carpet for a unique three-day get-together in Zurich, exclusively for our special clients and close contacts. Let’s talk & Co-work",
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-btq-meta-img.jpg',
  opengraphUrl: 'https://screver.com/events/screver-btq-program/',
};

const ScreverOcxProgram = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program scr-btq-event">
        <Header />

        <main>
          <Hero />
          <PagesList linksToPages={PAGES} />
        </main>
      </div>
    </>
  );
};

export default ScreverOcxProgram;
