import React /*, { useEffect, useState }*/ from 'react';
// import { useLocation } from '@reach/router';

import { section, inner } from './styles.module.scss';

// import { BTQ_PROGRAM_HERO } from 'mocks/screver-btq-program/hero';

const Hero = () => {
  // const { search } = useLocation();
  // const [person, setPerson] = useState('Hey there! ');

  // const urlParams = new URLSearchParams(search);

  // const userName = urlParams.get('name');

  // useEffect(() => {
  //   let name;

  //   name = userName ? `Hey there, <u>${userName}!</u> ` : 'Hey there! ';

  //   setPerson(name);
  // }, [userName]);

  return (
    <section className={section}>
      <div className="ocx-prog-wrap">
        <div className={inner}>
          {/* <span dangerouslySetInnerHTML={{ __html: person }}></span>

          <span>{BTQ_PROGRAM_HERO}</span> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
