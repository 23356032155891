import React from 'react';

import CompanyLogoWrap from '../components/CompanyLogoWrap';

import { inner } from './styles.module.scss';

const Header = () => {
  return (
    <header className="ocx-prog-wrap">
      <div className={inner}>
        <CompanyLogoWrap />
      </div>
    </header>
  );
};

export default Header;
